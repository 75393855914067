import React, { Component } from "react";
import background from "./Cityscape.png";
class App extends Component {
    render() {
        const myStyle = {
            backgroundImage: `url(${background})`,
            height: "200vh",
            marginTop: "-170px",
            fontSize: "50px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        };
        return (
            <div style={myStyle}>
            </div>
        );
    }
}

export default App;

